<template>
  <div
    class="page-container w-screen h-vh-9/10 max-vh-9/10 overflow-y-hidden relative flex flex-wrap justify-center"
  >
    <div class="container flex justify-center flex-wrap">
      <div
        class="flex flex-wrap w-screen justify-around items-center max-h-9/10-screen overflow-y-hidden"
      >
        <div class="flex flex-wrap w-full justify-center">
          <h1 class="w-full text-center my-5 text-primary text-3xl">
            ¡Hemos procesado tu pago! <br />
          </h1>
          <p
            class="bg-secondary border-2 border-secondary-light text-white rounded-xl px-5 py-3 w-full text-center"
          >
            Podrás ver y descargar tu póliza de seguro en el listado de pólizas
          </p>
          <p class="my-20">Espera un poco más, estamos generando tu póliza, la ventana se cerrará automáticamente.</p>
          <p class="my-20">La ventana se cerrará automáticamente.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APIServices } from "../../constants/config";
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      requiredParams: ["Aut", "Liq", "NAut", "TipOp"],
    };
  },
  computed: {},
  mounted() {
    this.$store.commit("updateBgApp", "");
    this.processPayment();
  },
  destroyed() {},
  methods: {
    ...mapGetters(["shoppingCar"]),
    async processPayment() {
      let self = this;
      let queryParams = self.$route.query;
      const hasAllKeys = self.requiredParams.every((key) => key in queryParams);
      let id = atob(self.$route.params.id).split("-")[0];
      //console.log(hasAllKeys, id);
      if (hasAllKeys && id) {
        let url = APIServices.product_payment + id + "/";
        let payload = {
          Aut: queryParams.Aut,
          Liq: queryParams.Liq,
          NAut: queryParams.NAut,
          TipOp: queryParams.TipOp,
          // RefProg: queryParams.RefProg,
        }
        
        if (String(queryParams.Aut) == "1") { // Pago autorizado
          //console.log(url);
          await self.$http.patch(url, payload).then(() => {
            // console.log("Result", result);
          }).catch((err) => {
            // console.log(err);
            throw err            
          }).finally(() => {
            // console.log("Cerrando");
            // self.shoppingCar.getQuoteWithDetails();
            window.close()
          });
        }
        
      }
    },
  },
};
</script>
<style ></style>
